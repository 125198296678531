<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <div class="flex justify-between mt-5">
        <h2 class="text-2xl font-bold page-title">Settlements Cycle 1</h2>
        <p class="justify-">
          Manual
          <input
            type="checkbox"
            role="switch"
            id="ms1"
            ref="switch"
            :checked="settlementType == 'manual' ? '' : 'checked'"
            @change="handleToggle(permission)"
          />
          Automatic
        </p>
      </div>
      <div class="flex justify-between items-end my-5">
        <div class="">
          <input
            type="text"
            class="inline-block px-3 ring-2 outline-none ring-blue-500 rounded-sm w-80 text-sm py-2"
            name=""
            placeholder="Search"
            id=""
            v-model="search"
          />
          <button
            @click="handleSearch"
            class="uppercase shadow ml-2 text-sm bg-blue-900 text-white hover:bg-blue-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
          >
            Search
          </button>
        </div>
        <div class="">
          <button
            @click="downloadCSV"
            class="block uppercase shadow ml-2 text-sm bg-blue-900 text-white hover:bg-blue-600 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
          >
            Download CSV
          </button>
        </div>
      </div>
      <div class="flex justify-end mt-14">
        <div class="">
          <select
            type="date"
            class="inline-block border-2 px-2 rounded-sm mr-2 px-3 py-2 bg-none"
            id=""
            v-model="filter"
          >
            <option value="">Select Filter</option>
            <option v-for="(filter, i) in filters" :key="i" :value="filter">
              {{ filter }}
            </option>
          </select>
        </div>
      </div>
      <div class="mt-3" style="height: 600px; overflow: auto">
        <table class="shadow-lg bg-white w-full relative border-collapse">
          <tr>
            <th
              class="text-l p-3 text-left"
              colspan="9"
              style="background: #dee6f2"
            >
              List of Settlements
            </th>
            <th
              class="text-l p-3 text-left"
              colspan="3"
              style="background: #dee6f2"
            >
              <button
                class="border-2 border-green-700 text-green-900 text-xs py-2 px-3 rounded-md mx-3 font-normal"
                @click="markAsSettled"
              >
                Mark as Settled ({{ selected.length }})
              </button>
              <button
                class="border-2 border-green-700 text-green-900 text-xs py-2 px-3 rounded-md font-normal"
                @click="settledTransaction"
              >
                Settle Transaction ({{ selected.length }})
              </button>
            </th>
          </tr>
          <tr>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              <input type="checkbox" v-model="selectAll" />
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Merch. ID
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Merchant name
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Store Name
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Account No.
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Bank
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Total Amount (NGN)
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Settled Amount (NGN)
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Date
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Aggregator
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Cycle Ref.
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Status
            </th>
          </tr>
          <tr v-for="(document, i) in filterAccounts" :key="i">
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              <input type="checkbox" :value="document.id" v-model="selected" />
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ document.merchant_id }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ document.merchant_name }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ document.store_name }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ document.account_number || "N/A" }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ document.bank || "N/A" }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ document.amount }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ document.net_fee_due_merchant }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ document.transaction_date }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ document.is_aggregator }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ document.cycle_ref || "N/A" }}
            </td>
            <td class="border whitespace-nowrap px-8 py-3 text-xs capitalize">
              {{ document.status }}
            </td>
          </tr>
        </table>
      </div>
      <div class="mt-3">
        <button
          @click="prevPage(currentPage)"
          :disabled="isDisabled"
          class="uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
        >
          &laquo; Prev
        </button>

        <button
          @click="nextPage(currentPage)"
          class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
        >
          Next &raquo;
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import Loading from "./general-components/Loading.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import moment from "moment";
import { GET_USER_DATA } from "../browser-db-config/localStorage";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      isDisabled: false,
      search: "",
      accounts: null,
      currentPage: 1,
      totalPages: 0,
      statusSort: "all",
      fraudulentFlag: false,
      notFraudulentFlag: false,
      numberOfItems: 0,
      settlementType: "manual",
      dateData: null,
      search: "",
      selected: [],
      filters: ["Bank", "Status", "Date", "Aggregator", "City Reference"],
      filter: "",
      page_size: 100,
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["GET_SETTLEMENT"]),
    filterAccounts() {
      if (this.GET_SETTLEMENT.data.transactions.items) {
        // return this.GET_SETTLEMENT.data.transactions.items;
        let k =
          this.GET_SETTLEMENT.data.transactions &&
          this.GET_SETTLEMENT.data.transactions.items.filter(
            (account) =>
              account.merchant_name
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
              account.terminal_id
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
              account.store_name
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
              account.merchant_id
                .toLowerCase()
                .includes(this.search.toLowerCase())
          );
        return k;
      } else {
        let k = [];
        return k;
      }
    },

    selectAll: {
      get: function () {
        return this.GET_SETTLEMENT.data.transactions.items
          ? this.selected.length ==
              this.GET_SETTLEMENT.data.transactions.items.length
          : false;
      },
      set: function (value) {
        var selected = [];

        if (value) {
          if (this.GET_SETTLEMENT.data.transactions.items !== null) {
            this.GET_SETTLEMENT.data.transactions.items.forEach(function (
              account
            ) {
              selected.push(account.id.toString());
            });
          }
        }

        this.selected = selected;
      },
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async mounted() {},
  async created() {
    const d = new Date();
    this.dateData = {
      startDate: new Date(d.setDate(d.getDate() - 7)).toJSON().split("T")[0],
      endDate: new Date().toJSON().split("T")[0],
      creationDate: new Date().toJSON().split("T")[0],
      startTime: "00:00",
      endTime: this.moment(d).format("HH:mm"),
    };
    await this.fetchSettlement();
  },
  methods: {
    ...mapActions([
      "FETCH_ALL_FRAUD",
      "UPDATE_FRAUD_STATUS",
      "FETCH_PAGINATED_FRAUD",
      "FETCH_SINGLE_FRAUD",
      "DOWNLOAD_FRAUD_CSV",
      "FETCH_FRAUD_TYPE",
    ]),
    async fetchSettlement() {
      this.isLoading = true;
      const payload = {
        group_code: this.$route.params.id,
        status: "",
        page_size: this.page_size,
      };
      try {
        await this.$store.dispatch("FETCH_SETTLEMENT", payload);
        this.isLoading = false;
      } catch (error) {}
    },
    async markAsSettled() {},
    async settledTransaction() {},
    async fetchAllFraud() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("FETCH_ALL_FRAUD");
        this.isLoading = false;
      } catch (error) {}
    },
    async markAsFraudulent(account, status) {
      let res = confirm("Are you sure you want to perform this operation?");
      this.isLoading = true;
      let payload = [account.business_id, status];
      if (res) {
        let res = await this.$store.dispatch("UPDATE_FRAUD_STATUS", payload);
        if (res.status) {
          //Track Event on MixPanel
          mixpanel.track("TA_ADMIN_FRAUD", {
            "Business Name": account.business_name,
            "Merchant Name": account.merchant_name,
            "Business Email": account.email,
            "Mark as Fraud": status,
          });
          alert("Status was updated successfully");
          setTimeout(() => {
            this.$router.go();
          }, 200);
        } else {
          alert(res.error);
        }
      }
      this.isLoading = false;
    },
    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_FRAUD",
        currPage - 1
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage -= 1;
      }
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_FRAUD",
        currPage + 1
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage += 1;
      }
    },
    async handleSearch() {
      this.isLoading = true;
      let res = await this.$store.dispatch("FETCH_SINGLE_FRAUD", this.search);
      console.log(res.status);
      if (!res.success && res.status === undefined) {
        this.isLoading = false;
        alert("Could not find " + this.search);
        console.log(this.isLoading);
        this.isLoading = false;
      } else if (res.status && res.success === undefined) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    fetchDatedRecords() {},
    clearDateFilter() {},
    async downloadCSV() {
      this.isLoading = true;

      let res = await this.$store.dispatch("DOWNLOAD_FRAUD_CSV");

      if (res.status) {
        window.open(res.data, "_blank");
      } else {
        alert("Could not find " + this.search);
        // console.log(res.data);
      }
      this.isLoading = false;
    },
    async fraudulent() {
      this.isLoading = true;

      this.fraudulentFlag = true;
      this.notFraudulentFlag = false;

      let res = await this.$store.dispatch("FETCH_FRAUD_TYPE", true);

      if (res.status) {
      } else {
        alert("Could not find any record");
        // console.log(res.data);
      }
      this.isLoading = false;
    },
    async notFraudulent() {
      this.isLoading = true;
      this.notFraudulentFlag = true;
      this.fraudulentFlag = false;

      let res = await this.$store.dispatch("FETCH_FRAUD_TYPE", false);

      if (res.status) {
      } else {
        alert("Could not find any record");
        // console.log(res.data);
      }
      this.isLoading = false;
    },

    async fetchAll() {
      this.isLoading = true;
      this.notFraudulentFlag = false;
      this.fraudulentFlag = false;

      let res = await this.$store.dispatch("FETCH_ALL_FRAUD");

      if (res.status) {
      } else {
        alert("Could not find any record");
        // console.log(res.data);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
input:where([type="checkbox"][role="switch"]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  color: inherit;
  font-size: inherit;
  width: 2em;
  height: 1em;
  box-sizing: content-box;
  border: 1px solid;
  border-radius: 1em;
  vertical-align: text-bottom;
  margin: auto;
}

input:where([type="checkbox"][role="switch"])::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  box-sizing: border-box;
  width: 0.7em;
  height: 0.7em;
  margin: 0 0.15em;
  border: 1px solid;
  border-radius: 50%;
  background: currentcolor;
}

input:checked {
  background-color: #253985;
}
input:where([type="checkbox"][role="switch"]):checked::before {
  left: 1em;
  background-color: #fff;
}

input:where([type="checkbox"][role="switch"]):disabled {
  opacity: 0.4;
}
</style>
